function showHide(ID) {
	var id = document.getElementById(ID);
	//var header = document.getElementById(headerID);
	//alert(currentClassName);
	if (id.style.display == "none") {
		id.style.display = "block";
	} else {
		id.style.display = "none";
	}
}

function toggleHoverBgImage(ID, boxID) {
	var id = document.getElementById(ID);
	var boxID = document.getElementById(boxID);
	if (boxID.style.display == "block")
	{
		id.className = "hp_expanded";
		//id.style.backgroundImage = "url(../images/menus/minus.gif)";
	} else {
		id.className = "hp_collapsed";
		//id.style.backgroundImage = "url(../images/menus/plus.gif)";
	}
}

function toggleClass(ID, boxID) {
	var id = document.getElementById(ID);
	var boxID = document.getElementById(boxID);
	var curClass = document.getElementById(id).className;
	var toggleClass = document.getElementById(nextClass);
	if (boxID.className == curClass )
	{
		id.className = "hp_expanded";
		//id.style.backgroundImage = "url(../images/menus/minus.gif)";
	} else {
		id.className = "hp_collapsed";
		//id.style.backgroundImage = "url(../images/menus/plus.gif)";
	}
}

function showHide2(ID, boxID) {
	var id = document.getElementById(ID);
	var boxID = document.getElementById(boxID);
	if (id.style.display == "none") {
		id.style.display = "block";
		boxID.className = "expanded";
		//Div1Over = true;
	} else {
		Div1Over = false;
		Div2Over = false;
		Div3Over = false;
		Div4Over = false;
		id.style.display = "none";
		boxID.className = "collapsed";
	}
}


function showHide3(ID, boxID) {
	var id = document.getElementById(ID);
	var boxID = document.getElementById(boxID);
	if (id.style.display == "none") {
		id.style.display = "";
		boxID.className = "expanded";
		//Div1Over = true;
	} else {
		Div1Over = false;
		Div2Over = false;
		Div3Over = false;
		Div4Over = false;
		id.style.display = "none";
		boxID.className = "collapsed";
	}
}



function customDojoClose(ID, boxID) {
	var id = document.getElementById(ID);
	var boxID = document.getElementById(boxID);
	if (id.style.display == "none") {
		id.style.display = "block";
		boxID.className = "expanded";
	} else {
		id.style.display = "none";
		boxID.className = "collapsed";
	}
}



function showHideLic(ID, boxID, theClass, CurrID) {
	// This function is written specifically for Insurance Licensing because it sucks. Bad.

	//First we capture the width of the current div before the show/hide for resizing purposes.
	var cssRules;
	var mydiv = document.getElementById(CurrID);
	var element = "width";
	var preCalc = mydiv.offsetWidth;
	
	if (preCalc != 0) {
		var value = mydiv.offsetWidth+20;	
	} else {
		var value = mydiv.offsetWidth;
	}
	
	//Then we show pull the show/hide
	var id = document.getElementById(ID);
	var boxID = document.getElementById(boxID);
	if (id.style.display == "none") {
		id.style.display = "block";
		boxID.className = "expanded";
	} else {
		id.style.display = "none";
		boxID.className = "collapsed";
	}

	//Then we change the styling on the width so it is the correct size.
	 if (document.all) {
	  cssRules = 'rules';
	 }
	 else if (document.getElementById) {
	  cssRules = 'cssRules';
	 }
	 var added = false;
	 for (var S = 0; S < document.styleSheets.length; S++){
	  for (var R = 0; R < document.styleSheets[S][cssRules].length; R++) {
	   if (document.styleSheets[S][cssRules][R].selectorText == theClass) {
	    if(document.styleSheets[S][cssRules][R].style[element]){
		document.styleSheets[S][cssRules][R].style[element] = value;
	    added=true;
		break;
	    }
	   }
	  }
  
	  if(!added){
	  if(document.styleSheets[S].insertRule){
			  
				document.styleSheets[S].insertRule(theClass+' { '+element+': '+value+'; }',document.styleSheets[S][cssRules].length);
			} else if (document.styleSheets[S].addRule) {
				document.styleSheets[S].addRule(theClass,element+': '+value+';');
			}
	  }
	 }	 	 
}
